import { Controller } from "stimulus";

/**
 * The DismissableController allows users to hide its content.  It
 * sets a class to animate the content out, then applies another
 * class to set its display to 'none' to completely remove it from the
 * layout.  'notice' type flash messages are automatically hidden after 5 seconds
 */
export default class extends Controller {
  connect() {
    if (this.data.get('dismiss-after')) {
      this.dismissToken = setTimeout(() => this.dismiss(), parseInt(this.data.get('dismiss-after')));
    }
  }

  dismiss() {
    this.element.classList.add("mh-dismissable--animate-out");
    this.hideToken = setTimeout(() => this.element.classList.add("hidden"), 250);
  }

  disconnect() {
    clearTimeout(this.dismissToken);
    clearTimeout(this.hideToken);
  }
}
