import { Controller } from "stimulus";
import { connect } from "tls";

const hiddenClass = "force-hidden";
const hide = el => {
  el.classList.add(hiddenClass);
};
const show = el => {
  el.classList.remove(hiddenClass);
};

const optionClasses = ["text-secondary", "bg-white", "visited:text-secondary"];
const selectedClasses = ["bg-secondary", "text-white", "visited:text-white"];
const select = el => {
  optionClasses.forEach((c) => {
    el.classList.remove(c);
  });
  selectedClasses.forEach((c) => {
    el.classList.add(c);
  });
}
const unselect = el => {
  optionClasses.forEach((c) => {
    el.classList.add(c);
  });
  selectedClasses.forEach((c) => {
    el.classList.remove(c);
  });
}

export default class extends Controller {
  static targets = ["container", "select", "option"];
  
  connect() {
    var container = this.containerTarget;
    this.selectedIndex = parseInt(container.dataset.selectedindex || 0);
    this.optionCount = this.optionTargets.length;
    this.updateDisplay();
  }

  updateDisplay() {
    for (var i = 0; i < this.optionCount; i++) {
      if (i == this.selectedIndex) {
        show(this.optionTargets[i]);
        select(this.selectTargets[i]);
      } else {
        hide(this.optionTargets[i]);
        unselect(this.selectTargets[i])
      }
    }
  }

  select(e) {
    this.selectedIndex = e.target.dataset.index;
    this.updateDisplay();
  }
}
