import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "options" ]

  connect() {
    this.showMenu = false;
    this.setMenuClasses();
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showMenu = !this.showMenu;
    this.setMenuClasses();
  }

  hide() {
    this.showMenu = false;
    this.setMenuClasses();
  }

  setMenuClasses() {
    if (this.showMenu) {
      this.optionsTarget.classList.remove('hidden');
    } else {
      this.optionsTarget.classList.add('hidden');
    }
  }
}
