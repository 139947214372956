import { Controller } from "stimulus";
import Tooltip from "tooltip.js";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    let title = this.data.get("title");
    if (title) {
      this.instance = new Tooltip(this.element, {title: title});
    } else {
      this.instance = null;
    }
  }

  disconnect() {
    if (this.instance) { this.instance.dispose() }
  }
}
