import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "select"];

  initialize() {
  }

  submit() {
    this.formTarget.submit();
  }
}