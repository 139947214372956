import { Controller } from "stimulus";
import { connect } from "tls";

const hiddenClass = "force-hidden";
const hide = el => {
  el.classList.add(hiddenClass);
};

const show = el => {
  el.classList.remove(hiddenClass);
};
export default class extends Controller {
  static targets = ["hide", "show", "content"];
  isOpen = false;

  connect() {
    if (!this.hasContentTarget) { return; }
    this.isOpen = this.data.get("open") || false;
    this.data.set("open", this.isOpen);
    this.updateDisplay();
  }

  updateDisplay() {
    if (this.isOpen) {
      show(this.contentTarget);
      hide(this.showTarget);
      show(this.hideTarget);
    } else {
      hide(this.contentTarget);
      show(this.showTarget);
      hide(this.hideTarget);
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.data.set("open", this.isOpen);
    this.updateDisplay();
  }
}
