import { Controller } from "stimulus";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

export default class extends Controller {
  connect() {
    const className = "mh-select-container";
    const classNamePrefix = "mh-select";

    const appliedProps = {
      className,
      classNamePrefix,
      ...this.jsonProps()
    };

    const SelectInput = props => {
      let [value, setValue] = useState(props.value);
      return <Select {...props} onChange={setValue} value={value} />;
    };
    ReactDOM.render(<SelectInput {...appliedProps} />, this.element);

    document.addEventListener("turbolinks:before-cache", this.unmountComponent);
  }

  disconnect() {
    document.removeEventListener(
      "turbolinks:before-cache",
      this.unmountComponent
    );
  }

  unmountComponent() {
    if (this.element) { ReactDOM.unmountComponentAtNode(this.element); }
  }

  jsonProps() {
    let jsonProps = this.data.get("props") || {};
    if (typeof jsonProps === "string") {
      jsonProps = JSON.parse(jsonProps);
    }

    return jsonProps;
  }
}
