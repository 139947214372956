import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['cardCvc', 'cardExpiry', 'cardNumber', 'errors', 'stripe-form'];

  connect() {
    // must style with px rather than REM, otherwise REM values calculated relative to size of Stripe's iframe
    var style = {
      base: {
        fontFamily: 'Open Sans, system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
        fontSize: '18px',

        '::placeholder': {
          color: '#a9a9a9',
        },
      }
    };

    // Create a Stripe client.
    const apiKeyEl = document.querySelector('meta[name=stripe-api-key]')
    if (apiKeyEl === null || apiKeyEl.content === undefined) return;
    this.stripe = Stripe(apiKeyEl.content);

    // Create an instance of Elements.
    this.elements = this.stripe.elements();

    // use function provided by mh-store gem that will inject Stripe html form elements into the page
    this.stripeFormAttach(this.element, style)
  }

  disconnect() {
    // use function provided by mh-store gem that will destroy any Stripe html form elements on the page
    // so that when page is reloaded, things begin from a prisine state
    this.stripeFormDetach(this.element);
  }

  hideFlash() {
    Array.from(document.querySelectorAll('.flash-message')).forEach((el) => {
      el.style.display = 'none';
    })
  }

  // create and mount Stripe Elements
  stripeFormAttach(form, style) {
    style ||= {}

    if (this.hasErrorsTarget) this.errorsTarget.style.display = 'none'

    if (this.hasCardNumberTarget) {
      this.cardNumber = this.elements.create('cardNumber', { style: style });
      this.cardNumber.mount(this.cardNumberTarget);
    }

    if (this.hasCardExpiryTarget) {
      this.cardExpiry = this.elements.create('cardExpiry', { placeholder: this.cardExpiryTarget.dataset.stripePlaceholderName, style: style });
      this.cardExpiry.mount(this.cardExpiryTarget);
    }

    if (this.hasCardCvcTarget) {
      this.cardCvc = this.elements.create('cardCvc', { placeholder: this.cardCvcTarget.dataset.stripePlaceholderName, style: style });
      this.cardCvc.mount(this.cardCvcTarget);
    }

    form.addEventListener('submit', (event) => this.stripeFormHandler(event));
  }

  // destroy Stripe Elements
  stripeFormDetach() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  }

  stripeFormHandler(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const submitButton = form.querySelector('input[type=submit]');
    submitButton.disabled = true;

    this.stripe.createToken(this.cardNumber).then((result) => {
      if (result.error) {
        // Inform the user if there was an error.
        if (this.hasErrorsTarget) {
          this.errorsTarget.textContent = result.error.message;
          this.errorsTarget.style.display = 'block';
        }

        // Reenable the submit button
        submitButton.removeAttribute('data-disable-with');
        submitButton.disabled = false;
      } else {
        // Send the token to your server.
        this.stripeTokenHandler(result.token, form);
      }
    });
  };

  // Submit the form with the token ID.
  stripeTokenHandler(token, form) {
    // Insert the token ID into the form so it gets submitted to the server
    const hiddenTokenField = document.querySelector('[data-stripe-role=stripe-token]');
    if (hiddenTokenField) hiddenTokenField.setAttribute('value', token.id);

    // Submit the form
    form.submit();
  }
}
