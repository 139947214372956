import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.modifyAnchors(this.element);
  }

  modifyAnchors(el) {
    let links = Array.from(el.querySelectorAll("a:not([data-external-link=false])"));
    links.forEach((a) => {
      if (a.href.startsWith("http")) {
        a.target = "_blank";
      }
    });
  }
}
