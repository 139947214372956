import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  onPostSuccess(event) {
    let response = event.detail[0];
    if (response.redirect) {
      window.Turbolinks.clearCache()
      window.Turbolinks.visit(response.redirect);
    } else {
      this.element.innerHTML = response.body;
    }
  }
}
