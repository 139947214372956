import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import {
  Application
} from "stimulus"
import {
  definitionsFromContext
} from "stimulus/webpack-helpers"

Rails.start();
Turbolinks.start();

const application = Application.start()
const context = require.context("./stimulus-controllers", true)
application.load(definitionsFromContext(context))